import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import EmbedPlayer from '../../components/EmbedPlayer';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Third party player controls should implement the fullscreen functionality on the client side with the `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/Guide/API/DOM/Using_full_screen_mode"
      }}>{`HTML5 Fullscreen API`}</a>{`.`}</p>
    <h3>{`Necessary steps`}</h3>
    <ul>
      <li parentName="ul">{`switch your embed container to fullscreen mode with HTML5 FullScreen API`}</li>
      <li parentName="ul">{`handle the iframe size accordingly`}</li>
      <li parentName="ul">{`IBM Video Streaming embed will adopt to the resized iframe size responsively`}</li>
    </ul>
    <h3>{`Example`}</h3>
    <EmbedPlayer src="https://video.ibm.com/embed/recorded/131906526?hideFullscreen" controls={['fullscreen']} mdxType="EmbedPlayer" />
    <h3>{`Javascript`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var fullScreenBtn = document.getElementById('fullScreenBtn');
var container = document.getElementById('Container');

document.addEventListener("fullscreenchange",changeHandler);
document.addEventListener("webkitfullscreenchange",changeHandler);
document.addEventListener("mozfullscreenchange",changeHandler);
document.addEventListener("MSFullscreenChange", changeHandler, false);

// trigger fullscreen change on our custom button click
fullScreenBtn.addEventListener('click',onFullScreenBtnClick);

// check if the current state is fullscreen or not
function isFullScreen() {
  return document.fullScreen ||
    document.webkitIsFullScreen ||
    document.mozfullScreen ||
    document.msFullscreenElement;
}

// change everything on other change events too
function changeHandler () {
  container.className = isFullScreen() ? 'fullscreen' : '';
}

// fullscreen button event handler
function onFullScreenBtnClick () {
  // if we are in fullscreen, then exit
  if (isFullScreen()) {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozExitFullScreen) {
      document.mozExitFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
    // if we are in non-fullscreen mode, open it
  } else {
    if (container.requestFullscreen) {
      container.requestFullscreen();
    } else if (container.webkitRequestFullscreen) {
      container.webkitRequestFullscreen();
    } else if (container.mozRequestFullScreen) {
      container.mozRequestFullScreen();
    } else if (container.msRequestFullscreen) {
      container.msRequestFullscreen();
    }
  }
}
`}</code></pre>
    <h3>{`HTML`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div id="Container">
  <iframe
    id="UstreamIframe"
    width="100%" height="100%"
    src="https://video.ibm.com/embed/recorded/131906526?hideFullscreen"
    frameborder="0"
    allowfullscreen
    referrerpolicy="no-referrer-when-downgrade"
  >
  </iframe>
  <div id="fullScreenBtn"> custom fullscreen button </div>
</div>
`}</code></pre>
    <h3>{`CSS`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`#Container {
  position: relative;
  width: 640px;
  height: 390px;
}

#Container.fullscreen {
  width: 100%;
  height: 100%;
}

#fullScreenBtn {
  cursor: pointer;
  position: absolute;
  bottom: 10px;
  right: 10px;
  padding: 5px;
  background: #38f;
  color: #fff;
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      